angular.module('fingerink')
    .controller('topnavabarCtrl', function ($scope, $mdSidenav, session) {
        $scope.controller = this;
        var that = this;
        that.plan = session.get().plan;
        that.user = session.get().user;

        $scope.$on("sessionChanged", () => {
            that.plan = session.get().plan;
            that.user = session.get().user;
        });


        if (session.get()) {
            that.userName = session.get().user;

            that.notificaciones = function () {
                $mdSidenav('notifications').toggle();
            };

            that.notificaciones = function () {
                $mdSidenav('notifications').toggle();
            };

            that.userNav = function () {
                $mdSidenav('userNav').toggle();
            };

            that.left = function () {
                $mdSidenav('leftNav').toggle();
            };
        }

        that.iniciales = (name, surname) => (name + (surname ? ' ' + surname : '')).split(' ').map(a => a[0]).reduce((a, b) => a + b);

    });
